import React from "react"
import Lottie from "react-lottie"
import lightModeIcon from "assets/json/Light Green.json"

const isBrowser = typeof window !== "undefined"

const LogoIcon = ({ height = "70px" }) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lightModeIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return isBrowser ? (
    <Lottie
      isClickToPauseDisabled={true}
      options={lottieOptions}
      height={height}
      width={height}
      style={{
        cursor: "default",
      }}
    />
  ) : null
}

export default LogoIcon
