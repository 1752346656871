import React from "react"

import LogoIcon from "./LogoIcon"

const FullPageLoader = () => {
  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <LogoIcon isSpining={true} />
    </div>
  )
}

export default FullPageLoader
